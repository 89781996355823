<template>
  <el-container class="admin-index-main">
    <el-main>
      <std-nav :paths="navData"></std-nav>
      <el-card class="app-list" v-show="detail_data===null">
        <std-table ref="module_table" :cols="userConfig.cols" :data="data.stores" :pageindex.sync="userConfig.pageIndex"
          :pagesize.sync="userConfig.pageSize" disable-selection force-refresh @refresh="onRefresh">
          <template #action="{row}">
            <el-link icon="el-icon-edit" @click="onEdit(row)">查看</el-link>
          </template>
        </std-table>
      </el-card>
      <template v-if="detail_data!==null">
        <el-card class="txt-detail-tool">
          <div style="text-align:right;">
            <el-button size="mini" icon="el-icon-d-arrow-right" type="primary" @click="detail_data=null">返回</el-button>
          </div>
        </el-card>
        <el-card class="txt-detail">
          <el-scrollbar class="myscroll1" style="height:600px">
            <pre v-html="detail_data"></pre>
          </el-scrollbar>
        </el-card>
      </template>
      <component v-if="dialog.show" :is="dialog.type" :windata="dialog.data" @cancel="onCloseDialog" @success="dialog.event"></component>
    </el-main>
    <el-aside>
      <el-card class="app-mgr">
        <template #header>
          基础操作
        </template>
        <el-date-picker v-model="searchKey" type="date" size="mini" placeholder="选择日期" value-format="yyyy-MM-dd" style="width:100%;"></el-date-picker>

        <div style="text-align:right;margin-top:5px;">
          <el-button type="primary" size="mini" icon="el-icon-plus" @click="onRefresh">查找指定日期日志</el-button>
        </div>
        <div class="tips">
          默认只会显示30天内的文本日志列表,如需之前的日期,请输入具体日期查询
        </div>
      </el-card>
      <normal-card></normal-card>
    </el-aside>
  </el-container>
</template>
<script>
import moment from 'moment'
export default {
  components: {
  },
  data() {
    return {
      showmode: 'list',
      //导航栏
      navData: [
        '回调文本日志'
      ],
      detail_data: null,
      //搜索关键字
      searchKey: '',
      searchTips: '请输入要搜索的 crm店铺号/店铺名/线上店铺号',
      userConfig: {
        cols: [
          { prop: 'date', label: '日期' },
          {
            prop: 'type', label: '类型', width: 100, show_type: 'option',
            options: [
              { text: '微信回调', value: 'WxBonus' },
              { text: '支付宝回调', value: 'AliBonus' }
            ]
          },
          { prop: 'size', label: '大小', width: 120 },
          { prop: 'last_time', label: '最后修改时间', width: 200 },
          { format: 'action', width: 80 }
        ],
        pageIndex: 1,
        pageSize: 20
      },
      dialog: {
        show: false,
        type: '',
        data: {},
        event: null
      },
      data: {
        stores: [],
        store_total: 0,
        crm_store_info: null
      },
      FuncButtons: [],
      userinfo: {},
      // 公告
      notices: []
    }
  },
  computed: {
    UserData() {
      return this.$store.state.global.LoginInfo
    }
  },
  methods: {
    onCloseDialog() {
      this.dialog.show = false
    },
    theShowDetail(record) {
      let now = this.data.stores.find(p => p.date == record.date && p.type == p.type)
      if (now.detail_data == null || moment().format('YYYY-MM-DD') == record.date) {
        this.Bus.ActionFunc('Sys-GetTxtLogDetail', {
          date: record.date,
          type: record.type
        }, data => {
          //给特定文字加样式,也可用于搜索
          //使用加锚点可以实现关键字定位
          //x.replace(/(?<=[\n]|^)[\S]+?:/g,p=>`<span class="">${p}</span>`)
          // if (moment().format('YYYY-MM-DD') != record.date) {
          let str = data.detail;
          str = str.replace(/请求入参:/g, `<span class="content-label">请求入参:</span>`)
            .replace(/请求签名:/g, `<span class="content-label">请求签名:</span>`)
            .replace(/解密后的数据:/g, `<span class="content-label">解密后的数据:</span>`)
            .replace(/响应结果:/g, `<span class="content-label">响应结果:</span>`)
            .replace(/CRM响应数据:/g, `<span class="content-label">CRM响应数据:</span>`)
            .replace(/提交给微信的数据:/g, `<span class="content-label">提交给微信的数据:</span>`)

          this.$set(now, 'detail_data', str)
          // }
          this.$set(this, 'detail_data', now.detail_data)
        })
      } else {
        this.$set(this, 'detail_data', now.detail_data)
      }
    },
    onEdit(record) {
      this.theShowDetail(Object.assign({}, record))
    },
    onRefresh() {
      let me = this
      this.Bus.ActionFunc("Sys-GetTxtLogs", {
        date: this.Bus.isEmpty(this.searchKey) ? undefined : this.searchKey
      }, data => {
        this.$set(this.data, 'stores', data)
      })
    },
    onToUrl(url) {
      this.$router.push({
        path: url
      })
      //location.href='/#'+url
    }
  },
  mounted() {
    this.onRefresh()
  }
}
</script>
<style lang="scss">
.tips {
  font-size: 13px;
  padding: 10px 0;
  color: #626161;
}
.admin-index-main {
  width: 1200px;
  margin: 0 auto;
  > .el-main {
    > .el-card {
      margin-bottom: 10px;
    }
    .txt-detail {
      margin-top: 5px;
      .el-card__body {
        padding: 5px;
      }
      pre {
        margin: 0px;
        font-size: 13px;
        line-height: 18px;
        white-space: break-spaces;
        word-break: break-all;
      }
      .content-label {
        color: #1247c4;
        font-weight: bold;
      }
    }
    .txt-detail-tool {
      .el-card__body {
        padding: 5px;
      }
    }
  }
  > .el-aside {
    overflow: visible;
    padding-bottom: 10px;

    .el-card {
      margin-bottom: 10px;
    }

    .list {
      padding-bottom: 10px;
      .main {
        > .el-link {
          float: left;
          clear: both;
        }
      }
    }
  }
}
</style>